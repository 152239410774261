import { useTheme } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import { tokens } from "../theme";
//import { mockBarData as data } from "../data/mockData";
import Plot from "react-plotly.js";

const CategoricalAxes = ({ isDashboard = false, data = [] }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  
  const labels = data.map((stat) => stat.label);
  const values = data.map((stat) => stat.value);

  const statData = [
    {
      type: 'scatter',
      mode: 'markers',
      x: labels,
      y: values,
      marker: {
        color: '#8884d8', // You can customize the color
      },
    },
  ];
  const layout = {
    // title: {
    //   text:"Surface for forest and food production vs available land over time",
    //   wrap: 200,
    //   font: {
    //     size: 12, // Adjust the font size as needed
    //   },
    // },
    xaxis: { title: 'Time' },
    yaxis: { title: 'Food Production' },
    height: 290, // Set the desired height in pixels
    width: 340,
    paper_bgcolor: 'rgba(0, 0, 0, 0)', // Set the chart background color to transparent
    plot_bgcolor: 'rgba(0, 0, 0, 0)',  // Set the plot area background color to transparent
    font: { color: theme === 'light' ? 'black' : 'white' }, // Set text color based on theme
  };

  return <Plot data={statData} layout={layout} />;
};

export default CategoricalAxes;
