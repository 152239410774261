import { useTheme } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import { tokens } from "../theme";
//import { mockBarData as data } from "../data/mockData";
import Plot from "react-plotly.js";


const BarCharts = ({ isDashboard = false, data = [] }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const labels = data.map((stat) => stat.label);
  const values = data.map((stat) => stat.value);

  const statData = [
    {
      type: 'bar',
      x: labels,
      y: values,
      marker: {
        color: '#8884d8', // You can customize the color
      },
    },
  ];
  const layout = {
    // title: "Bar Chart",
    xaxis: { title: 'Year' },
    yaxis: { title: 'Energy Consumption(MTone)' },
    height: 290, // Set the desired height in pixels
    width: 300,
    paper_bgcolor: 'rgba(0, 0, 0, 0)', // Set the chart background color to transparent
    plot_bgcolor: 'rgba(0, 0, 0, 0)',  // Set the plot area background color to transparent
    font: { color: theme === 'light' ? 'black' : 'white' }, // Set text color based on theme
  };

  return <Plot data={statData} layout={layout} />;
};

export default BarCharts;
