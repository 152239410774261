import { ResponsiveLine } from "@nivo/line";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import { useContext } from "react";
import Plot from 'react-plotly.js';

// import { mockLineData as data } from "../data/mockData";
const LineChart = ({ isCustomLineColors = false, isDashboard = false, data = [] }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const layout = {
    // title: "Historical Temperature",
    xaxis: { title: 'Year' },
    yaxis: { title: 'Price in Dollar' },
    height: 300, // Set the desired height in pixels
    width: 280,
    paper_bgcolor: 'rgba(0, 0, 0, 0)', // Set the chart background color to transparent
    plot_bgcolor: 'rgba(0, 0, 0, 0)',  // Set the plot area background color to transparent
    font: { color: theme === 'light' ? 'black' : 'white' }, // Set text color based on theme
  };

  const chartType = 'scatter';
  const chartMode = 'lines';
  let chartData = data.map(d => ({ ...d, name: d.id, x: d.data.map(d => d.x), y: d.data.map(d => d.y), type: chartType, mode: chartMode }))
  return (
    <Plot data={chartData} layout={layout}></Plot>
    // <ResponsiveLine
    //   data={data}
    //   theme={{
    //     axis: {
    //       domain: {
    //         line: {
    //           stroke: colors.grey[100],
    //         },
    //       },
    //       legend: {
    //         text: {
    //           fill: colors.grey[100],
    //         },
    //       },
    //       ticks: {
    //         line: {
    //           stroke: colors.grey[100],
    //           strokeWidth: 1,
    //         },
    //         text: {
    //           fill: colors.grey[100],
    //         },
    //       },
    //     },
    //     legends: {
    //       text: {
    //         fill: colors.grey[100],
    //       },
    //     },
    //     tooltip: {
    //       container: {
    //         color: colors.primary[500],
    //       },
    //     },
    //   }}
    //   colors={isDashboard ? { datum: "color" } : { scheme: "nivo" }} // added
    //   margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
    //   xScale={{ type: "point" }}
    //   yScale={{
    //     type: "linear",
    //     min: "auto",
    //     max: "auto",
    //     stacked: true,
    //     reverse: false,
    //   }}
    //   yFormat=" >-.2f"
    //   curve="catmullRom"
    //   axisTop={null}
    //   axisRight={null}
    //   axisBottom={{
    //     orient: "bottom",
    //     tickSize: 0,
    //     tickPadding: 5,
    //     tickRotation: 0,
    //     legend: "Year", // added
    //     legendOffset: 36,
    //     legendPosition: "middle",
    //   }}
    //   axisLeft={{
    //     orient: "left",
    //     tickValues: 5, // added
    //     tickSize: 3,
    //     tickPadding: 5,
    //     tickRotation: 0,
    //     legend: "Temperature", // added
    //     legendOffset: -40,
    //     legendPosition: "middle",
    //   }}
    //   enableGridX={false}
    //   enableGridY={false}
    //   pointSize={8}
    //   pointColor={{ theme: "background" }}
    //   pointBorderWidth={2}
    //   pointBorderColor={{ from: "seriesColor" }}
    //   pointLabelYOffset={-12}
    //   useMesh={true}
    //   legends={[
    //     {
    //       anchor: "bottom-right",
    //       direction: "column",
    //       justify: false,
    //       translateX: 100,
    //       translateY: 0,
    //       itemsSpacing: 0,
    //       itemDirection: "left-to-right",
    //       itemWidth: 80,
    //       itemHeight: 20,
    //       itemOpacity: 0.75,
    //       symbolSize: 12,
    //       symbolShape: "circle",
    //       symbolBorderColor: "rgba(0, 0, 0, .5)",
    //       effects: [
    //         {
    //           on: "hover",
    //           style: {
    //             itemBackground: "rgba(0, 0, 0, .03)",
    //             itemOpacity: 1,
    //           },
    //         },
    //       ],
    //     },
    //   ]}
    // />
  );
};

export default LineChart;
