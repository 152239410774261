import { Box, Button,Stack, IconButton, Slider, Typography, useTheme, Input, TextField } from "@mui/material";
import { Tab, Tabs } from '@mui/material';
// import TabPanel from '@mui/material/TabPanel';
import React, { useState } from 'react';
import { tokens } from "../../theme";
import { mockTransactions } from "../../data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import GeographyChart from "../../components/GeographyChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import ProgressCircle from "../../components/ProgressCircle";
import { changeBarData, changeGeographyData, changeLineData, changePieData, changeStatData } from "../../reducer/chartSlice";
import { useDispatch, useSelector } from "react-redux";
import { createContext } from "react";
import { randomNumberBetween } from "@mui/x-data-grid/utils/utils";
import PieChart from "../../components/PieChart";
import * as XLSX from 'xlsx';
import EditableGridTable from "../../components/EditableGridTable";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import BarCharts from "../../components/BarCharts";
import CategoricalAxes from "../../components/CategoricalAxes";
import DumbbellPlots from "../../components/DumbbellPlots";
import { Await } from "react-router-dom";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  let lineChartData = useSelector(state => state.lineChart.lineChart);
  let barChartData = useSelector(state => state.lineChart.barChart);
  let geographyChartData = useSelector(state => state.lineChart.geographyChart);
  let statChartData = useSelector(state => state.lineChart.statChart);
  let [sliderRenewableValue, setSliderRenewableValue] = useState(30);

  const pieChartData = useSelector(state => state.lineChart.pieChart);

  const tableColumns = [
    "Option 1",
    "Option 2",
    "Option 3",
    "Option 4",
  ];

  const tableData = [
    {data:[{label:"Vegetable Picking Device","Option 1": "Doe1.png", "Option 2": "Doe1.2.png", "Option 3": "Doe1.3.png", "Option 4": "Doe1.4.png"}]},
    {data:[{label:"Vegetable placing Device","Option 1": "Doe2.png", "Option 2": "Doe2.2.png", "Option 3": "Doe2.3.png", "Option 4": "Doe2.4.png"}]},
    {data:[{label:"Dirt Sifting Device","Option 1": "Doe3.1.png", "Option 2": "Doe3.png", "Option 3": "Doe3.3.png", "Option 4": "blank.png"}]},
    {data:[{label:"Packaging Device","Option 1": "Doe4.1.png", "Option 2": "Doe4.2.png", "Option 3": "Doe4.3.png", "Option 4": "Doe4.4.png"}]},
    {data:[{label:"Method of Transportation","Option 1": "Doe5.1.png", "Option 2": "Doe5.2.png", "Option 3": "Doe5.3.png", "Option 4": "blank.png"}]}
//     {label: "Power Source", "Option 1": "Hand Pushed", "Option 2": "Horse Drawn", "Option 3": "Wind Blown", "Option 4": "Pedal Driven"}
  ]
  const changeLineChartDataFun = async(value) => {
    // let temp = [
    //   {
    //     id: "Renewable",
    //     color: tokens("dark").greenAccent[500],
    //     data: [
    //       {
    //         x: "2008",
    //         y: Math.floor(Math.random() * 500),
    //       },
    //       {
    //         x: "2010",
    //         y: Math.floor(Math.random() * 500),
    //       },
    //       {
    //         x: "2012",
    //         y: Math.floor(Math.random() * 500),
    //       },
    //       {
    //         x: "2014",
    //         y: Math.floor(Math.random() * 500),
    //       },
    //       {
    //         x: "2016",
    //         y: Math.floor(Math.random() * 500),
    //       },
    //       {
    //         x: "2018",
    //         y: Math.floor(Math.random() * 500),
    //       },
    //       {
    //         x: "2020",
    //         y: Math.floor(Math.random() * 500),
    //       },
    //       {
    //         x: "2022",
    //         y: Math.floor(Math.random() * 500),
    //       },
    //       {
    //         x: "2024",
    //         y: Math.floor(Math.random() * 500),
    //       },
    //       {
    //         x: "2026",
    //         y: Math.floor(Math.random() * 500),
    //       },
    //       {
    //         x: "2028",
    //         y: Math.floor(Math.random() * 500),
    //       },
    //       {
    //         x: "2030",
    //         y: Math.floor(Math.random() * 500),
    //       },
    //     ],
    //   },
    //   {
    //     id: "Fossil",
    //     color: tokens("dark").blueAccent[300],

    //     data: [
    //       {
    //         x: "2008",
    //         y: Math.floor(Math.random() * 500),
    //       },
    //       {
    //         x: "2010",
    //         y: Math.floor(Math.random() * 500),
    //       },
    //       {
    //         x: "2012",
    //         y: Math.floor(Math.random() * 500),
    //       },
    //       {
    //         x: "2014",
    //         y: Math.floor(Math.random() * 500),
    //       },
    //       {
    //         x: "2016",
    //         y: Math.floor(Math.random() * 500),
    //       },
    //       {
    //         x: "2018",
    //         y: Math.floor(Math.random() * 500),
    //       },
    //       {
    //         x: "2020",
    //         y: Math.floor(Math.random() * 500),
    //       },
    //       {
    //         x: "2022",
    //         y: Math.floor(Math.random() * 500),
    //       },
    //       {
    //         x: "2024",
    //         y: Math.floor(Math.random() * 500),
    //       },
    //       {
    //         x: "2026",
    //         y: Math.floor(Math.random() * 500),
    //       },
    //       {
    //         x: "2028",
    //         y: Math.floor(Math.random() * 500),
    //       },
    //       {
    //         x: "2030",
    //         y: Math.floor(Math.random() * 500),
    //       },
    //     ],
    //   },
    //   {
    //     id: "CCUS",
    //     color: tokens("dark").redAccent[200],
    //     data: [
    //       {
    //         x: "2008",
    //         y: Math.floor(Math.random() * 500),
    //       },
    //       {
    //         x: "2010",
    //         y: Math.floor(Math.random() * 500),
    //       },
    //       {
    //         x: "2012",
    //         y: Math.floor(Math.random() * 500),
    //       },
    //       {
    //         x: "2014",
    //         y: Math.floor(Math.random() * 500),
    //       },
    //       {
    //         x: "2016",
    //         y: Math.floor(Math.random() * 500),
    //       },
    //       {
    //         x: "2018",
    //         y: Math.floor(Math.random() * 500),
    //       },
    //       {
    //         x: "2020",
    //         y: Math.floor(Math.random() * 500),
    //       },
    //       {
    //         x: "2022",
    //         y: Math.floor(Math.random() * 500),
    //       },
    //       {
    //         x: "2024",
    //         y: Math.floor(Math.random() * 500),
    //       },
    //       {
    //         x: "2026",
    //         y: Math.floor(Math.random() * 500),
    //       },
    //       {
    //         x: "2028",
    //         y: Math.floor(Math.random() * 500),

    //       },
    //       {
    //         x: "2030",
    //         y: Math.floor(Math.random() * 500),
    //       },
    //     ],
    //   }
    // ]

    

    let temp = (await(await fetch(process.env.REACT_APP_WITNESSEXPLORATION_BACKEND_URL + `/slider/chart1?value=${value}`)).json())?.map((data, index) => {
        return {id: data.id, color: tokens("dark").blueAccent[((index+1) % 9) * 10 ], data: [{ x: data.Years, y: data.Price_in_Dollar }]
    }}) || []
    let temp2 = (await(await fetch(process.env.REACT_APP_WITNESSEXPLORATION_BACKEND_URL + `/slider/chart2?value=${value}`)).json())?.map((data, index) => {
        return {value: data.Temperature.toString(), label: data.Years}
    }) || []
    let temp3 = (await (await fetch(process.env.REACT_APP_WITNESSEXPLORATION_BACKEND_URL + `/slider/chart3?value=${value}`)).json())?.map((data,index) => {
      return {value: data.Climate_Death.toString(), label: data.Year}
    }) || []
    let temp4 = (await (await fetch (process.env.REACT_APP_WITNESSEXPLORATION_BACKEND_URL + `/slider/chart4?value=${value}`)).json())?.map((data,index) => {
      return {value: data.Gross_In_Kcal.toString(), label: data.Year}
    }) || []

    dispatch(changeLineData(temp))
    dispatch(changeStatData(temp2))
    dispatch(changePieData(temp3))
    dispatch(changeBarData(temp4))
  }
  const changeBarChartDataFun = (event, value) => {
    let temp = [
      {"Temperature": 10, "year": 2010, "temperature": Math.floor(Math.random() * 10)},
      {"Temperature": 20, "year": 2011, "temperature": Math.floor(Math.random() * 10)},
      {"Temperature": 30, "year": 2012, "temperature": Math.floor(Math.random() * 10)},
      {"Temperature": 15, "year": 2010, "temperature": Math.floor(Math.random() * 10)},
      {"Temperature": 12, "year": 2011, "temperature": Math.floor(Math.random() * 10)},
      {"Temperature": 6, "year": 2012, "temperature": Math.floor(Math.random() * 10)},
      {"Temperature": 3, "year": 2010, "temperature": Math.floor(Math.random() * 10)},
      {"Temperature": 34, "year": 2011, "temperature": Math.floor(Math.random() * 10)},
      {"Temperature": 40, "year": 2012, "temperature": Math.floor(Math.random() * 10)},
      // Add ]more data as needed
    ]
    // dispatch(changeBarData(temp));
    // changeStatDataFun();
    changeLineChartDataFun(value);
    // changePieChartDataFun()
  }
  const changeStatDataFun = () => {
    let temp = [
      { "label": 2010, "value": Math.floor(Math.random() * 10) },
      { "label": 2011, "value": Math.floor(Math.random() * 10) },
      { "label": 2012, "value": Math.floor(Math.random() * 10) },
      { "label": 2010, "value": Math.floor(Math.random() * 10) },
      { "label": 2011, "value": Math.floor(Math.random() * 10) },
      { "label": 2012, "value": Math.floor(Math.random() * 10) },
      { "label": 2010, "value": Math.floor(Math.random() * 10) },
      { "label": 2011, "value": Math.floor(Math.random() * 10) },
      { "label": 2012, "value": Math.floor(Math.random() * 10) },
    ]
    dispatch(changeStatData(temp));
  }

  const changePieChartDataFun = () => {
    let temp = [
      {
        id: 1,
        label: "India",
        value: Math.floor(Math.random() * 500),
        color: "hsl(104, 70%, 50%)",
      },
      {
        id: 2,
        label: "UK",
        value: Math.floor(Math.random() * 500),
        color: "hsl(162, 70%, 50%)",
      },
      {
        id: 3,
        label: "France",
        value: Math.floor(Math.random() * 500),
        color: "hsl(291, 70%, 50%)",
      },
      {
        id: 4,
        label: "Germany",
        value: Math.floor(Math.random() * 500),
        color: "hsl(229, 70%, 50%)",
      },
      {
        id: 5,
        label: "Dubai",
        value: Math.floor(Math.random() * 500),
        color: "hsl(344, 70%, 50%)",
      },
    ]
    dispatch(changePieData(temp));
  }

  const handleAllLineDataDownload = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(lineChartData);
    XLSX.utils.book_append_sheet(wb, ws, 'all_data'); // You can change the sheet name here

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'all_data.xlsx'; // Set the file name here

    link.click();
  };


  const handleAllBarDataDownload = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(barChartData);

    XLSX.utils.book_append_sheet(wb, ws, 'all_data'); // You can change the sheet name here

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'all_data.xlsx'; // Set the file name here

    link.click();
  };
  const handleAllGeographDataDownload = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(geographyChartData);

    XLSX.utils.book_append_sheet(wb, ws, 'all_data'); // You can change the sheet name here

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'all_data.xlsx'; // Set the file name here

    link.click();
  };
  const handleAllPieDataDownload = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(pieChartData);

    XLSX.utils.book_append_sheet(wb, ws, 'all_data'); // You can change the sheet name here

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'all_data.xlsx'; // Set the file name here

    link.click();
  };

  const [value, setValue] = React.useState('1');

  const [openSaveDoe, setOpenSaveDoe] = useState(false);
  const handleSave = () => {
    setOpenSaveDoe(true)
  };
  const handleDoeSave = () => {
    setOpenSaveDoe(false)
  };
  const openNextTab = () => {
    setValue("2");
  }

  const rows = [{}]

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box m="-40px 20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {/* <Header title="DASHBOARD" subtitle="Welcome to Witness Exploration" /> */}
      </Box>
     {/*  <Box>
        <Tabs value={value} onChange={handleChange} centered sx={{ height: '60px' }}>
            <Tab label="Main Scenario Assumptions" value="1" sx={{ fontSize:"18px", width: '50%', textTransform: 'none', backgroundColor: value == 1 ? 'white' : 'inherit' }} />
            <Tab label="Scenario Charts" value="2" sx={{ fontSize:"18px", width: '50%', textTransform: 'none', backgroundColor: value == 2 ? 'white' : 'inherit' }} />
        </Tabs>
      </Box> */}
            {/* <TabPanel></TabPanel> */}
            {/* <div hidden={value != 1}>
              {tableData.map((item)=>{
                return <EditableGridTable data={item.data} columns={tableColumns} />
              })}
              <div style={{ padding: '10px', 'marginTop': "10px", display: 'flex'}}>
        <Button variant="contained" onClick={handleSave} style={{ 'text-transform': "none", backgroundColor: colors.blueAccent[600] }}>
          Save DoE
        </Button>
        <Button variant="contained" style={{ 'text-transform': "none", backgroundColor: colors.blueAccent[600], marginLeft: "10px" }}>
          Reset
        </Button>
        <Button variant="contained" onClick={openNextTab} style={{ 'text-transform': "none", backgroundColor: colors.blueAccent[600], marginLeft: "10px" }}>
          Next
        </Button>
      </div>
      
              <Dialog open={openSaveDoe} onClose={handleDoeSave} variant="contained" maxWidth='md' style={{ margin: 'auto' }}>
        <div style={{background: colors.primary[400]}} >

           <DialogTitle>Save DoE</DialogTitle> 
          <DialogContent style={{ minWidth: '20vw' }}>
            <TextField label="Enter something"
      variant="outlined"/>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDoeSave} variant="contained" color="primary">
              Save
            </Button>
          </DialogActions>
        </div>
      </Dialog>
            </div> */}
            {/* <div hidden={value!=2} m="10px"> */}
              <Box
              
              style={{marginTop:30}}
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="140px"
                gap="20px"
              >
                <Box
                  gridColumn="span 4"
                  gridRow="span 2"
                  backgroundColor={colors.primary[400]}

                >
                  <Box
                    mt="10px"
                    p="0 30px"
                    display="flex "
                    alignItems="center"
                  >
                      <Typography
                        variant="h5"
                        fontWeight="600"
                        color={colors.grey[100]}
                      >
                        Distribution of investment on each energy vs years
                      </Typography>
                    {/* <Box >
                      <IconButton onClick={handleAllLineDataDownload}>
                      <DownloadOutlinedIcon
                      sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                      />
                      </IconButton>
                    </Box> */}
                  </Box>
                  <Box m="-50px 0 0 0">  
                    <LineChart data={lineChartData} />
                  </Box>
                </Box>
                <Box
                  gridColumn="span 4 "
                  gridRow="span 2"
                  backgroundColor={colors.primary[400]}
                  
                >
                  <Box
                    mt="10px"
                    p="0 30px"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"

                  >
                      <Typography
                        variant="h5"
                        fontWeight="600"
                        color={colors.grey[100]}
                      >
                      Temperature and GHG evolution over the years
                      </Typography>
                    {/* <Box>
                      <IconButton onClick={handleAllBarDataDownload}>
                        <DownloadOutlinedIcon
                          sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                        />
                      </IconButton>
                    </Box> */}
                  </Box>
                  <Box height="120px" m="-50px 0 0 0">
                    <BarChart data={statChartData} isDashboard={true} />
                  </Box>
                </Box>
                {/* <Box */}
        {/*           gridColumn="span 4" */}
        {/*           gridRow="span 2" */}
        {/*           backgroundColor={colors.primary[400]} */}
        {/*         > */}
        {/*           <Box */}
        {/*             mt="10px" */}
        {/*             p="0 30px" */}
        {/*             display="flex " */}
        {/*             justifyContent="space-between" */}
        {/*             alignItems="center" */}
        {/*           > */}
        {/*             <Box> */}
        {/*               <Typography */}
        {/*                 variant="h5" */}
        {/*                 fontWeight="600" */}
        {/*                 color={colors.grey[100]} */}
        {/*               > */}
        {/*                World Population over the Temperature */}
        {/*               </Typography> */}
        {/*               <Typography */}
        {/*                 variant="h3" */}
        {/*                 fontWeight="bold" */}
        {/*                 color={colors.greenAccent[500]} */}
        {/*               > */}
        {/*                  */}{/* $59,342.32 */}
        {/*               </Typography> */}
        {/*             </Box> */}
        {/*             <Box> */}
        {/*               <IconButton onClick={handleAllGeographDataDownload} > */}
        {/*                 <DownloadOutlinedIcon */}
        {/*                   sx={{ fontSize: "26px", color: colors.greenAccent[500],  }} */}
        {/*                 /> */}
        {/*               </IconButton> */}
        {/*             </Box> */}
        {/*           </Box> */}
        {/*           <Box height="120px" m="-20px 0 0 0"  > */}
        {/*             <GeographyChart data={geographyChartData} isDashboard={true} /> */}
        {/*           </Box> */}
        {/*         </Box> */}
                <Box
                  gridColumn="span 4"
                  gridRow="span 2"
                  backgroundColor={colors.primary[400]}

                >
                  <Box
                    mt="10px"
                    p="0 30px"
                    display="flex "
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box>
                      <Typography
                        variant="h5"
                        fontWeight="600"
                        color={colors.grey[100]}
                      >
                      World Population and cumulative climate death vs years
                      </Typography>
                      <Typography
                        variant="h3"
                        fontWeight="bold"
                        color={colors.greenAccent[500]}
                      >
                        {/* $59,342.32 */}
                      </Typography>
                    </Box>
                    {/* <Box>
                      <IconButton onClick={handleAllPieDataDownload}>
                        <DownloadOutlinedIcon
                          sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                        />
                      </IconButton>
                    </Box> */}
                  </Box>
                  <Box height="120px" m="-50px 0 0 0"
                  >
                    <PieChart data={statChartData} isDashboard={true}/>
                  </Box>
                </Box>
                <Box
                  gridColumn="span 4 "
                  gridRow="span 2"
                  backgroundColor={colors.primary[400]}
                >
                  <Box
                    mt="10px"
                    p="0 30px"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box>
                      <Typography
                        variant="h5"
                        fontWeight="600"
                        color={colors.grey[100]}
                      >
                        Gross and net of damage output per year
                      </Typography>
                      <Typography
                        variant="h3"
                        fontWeight="bold"
                        color={colors.greenAccent[500]}
                      >
                        {/* $59,342.32 */}
                      </Typography>
                    </Box>
                    {/* <Box>
                      <IconButton onClick={handleAllBarDataDownload}>
                        <DownloadOutlinedIcon
                          sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                        />
                      </IconButton>
                    </Box> */}
                  </Box>
                  <Box height="120px" m="-50px 0 0 0">
                      <DumbbellPlots data={statChartData} isDashboard={true} />
                  </Box>
                </Box>
                <Box
                  gridColumn="span 4 "
                  gridRow="span 2"
                  backgroundColor={colors.primary[400]}
                >
                  <Box
                    mt="10px"
                    p="0 30px"
                    display="flex"
                    alignItems="center"
                  >
                    <Box>
                      <Typography
                        variant="h5"
                        fontWeight="600"
                        color={colors.grey[100]}
                      >
                      Surface for forest and food production vs available land over time
                      </Typography>
                      <Typography
                        variant="h3"
                        fontWeight="bold"
                        color={colors.greenAccent[500]}
                      >
                        {/* $59,342.32 */}
                      </Typography>
                    </Box>
                    {/* <Box>
                      <IconButton onClick={handleAllBarDataDownload}>
                        <DownloadOutlinedIcon
                          sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                        />
                      </IconButton>
                    </Box> */}
                  </Box>
                  <Box height="120px" m="-50px 0 0 0">
                    <CategoricalAxes data={statChartData} isDashboard={true} />
                  </Box>
                </Box>
                <Box
                  gridColumn="span 4 "
                  gridRow="span 2"
                  backgroundColor={colors.primary[400]}
                >
                  <Box
                    mt="10px"
                    p="0 30px"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box>
                      <Typography
                        variant="h5"
                        fontWeight="600"
                        color={colors.grey[100]}
                      >
                      Net Energies production consumption and mean price out of energy mix
                      </Typography>
                      <Typography
                        variant="h3"
                        fontWeight="bold"
                        color={colors.greenAccent[500]}
                      >
                        {/* $59,342.32 */}
                      </Typography>
                    </Box>
                    {/* <Box>
                      <IconButton onClick={handleAllBarDataDownload}>
                        <DownloadOutlinedIcon
                          sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                        />
                      </IconButton>
                    </Box> */}
                  </Box>
                  <Box height="120px" m="-50px 0 0 0">
                    <BarCharts data={statChartData} isDashboard={true} />
                  </Box>
                </Box>
              <Box
                  gridColumn="span 4"
                  gridRow="span 1"
                  backgroundColor={colors.primary[400]}
                  textAlign="center"
                >
                      <Typography
                        variant="h5"
                        fontWeight="600"
                        color={colors.grey[100]}
                        
                      >
                  Energy Mix
                </Typography>
                <div style={{ padding: '0 20px', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px' }}>
                  <div>
                  <Typography
                    variant="h6"
                    fontWeight="300"
                    color={colors.grey[100]}
                    align="left"
                  >
                    Renewable
                  </Typography>
                  <Slider
                    aria-label="Coal"
                    defaultValue={sliderRenewableValue}
                    onChangeCommitted={changeBarChartDataFun}
                    valueLabelDisplay="auto"
                    step={10}
                    marks
                    min={10}
                    max={110}
                    color="warning"
                    padding="20px"
                  />
                  </div>
                  <div>
                  <Typography
                    variant="h6"
                    fontWeight="300"
                    color={colors.grey[100]}
                    align="left"
                  >
                    Fossil
                  </Typography>
                  <Slider
                    aria-label="Coal"
                    defaultValue={30}
                    onChangeCommitted={changeBarChartDataFun}
                    valueLabelDisplay="auto"
                    step={10}
                    marks
                    min={10}
                    max={110}
                    color="warning"
                    padding="0px"
                  />
                  </div>
                  <div>
                  <Typography
                    variant="h6"
                    fontWeight="300"
                    color={colors.grey[100]}
                    align="left"
                  >
                    CCUS
                  </Typography>
                  <Slider
                    aria-label="Coal"
                    defaultValue={20}
                    onChangeCommitted={changeBarChartDataFun}
                    valueLabelDisplay="auto"
                    step={10}
                    marks
                    min={10}
                    max={110}
                    color="warning"
                    padding="20px"
                  />
                  </div>
                  <div>
                  <Typography
                    variant="h6"
                    fontWeight="300"
                    color={colors.grey[100]}
                    align="left"
                  >
                    Nuclear
                  </Typography>
                  <Slider
                    aria-label="Coal"
                    defaultValue={30}
                    onChangeCommitted={changeBarChartDataFun}
                    valueLabelDisplay="auto"
                    step={10}
                    marks
                    min={10}
                    max={110}
                    color="warning"
                    padding="20px"
                  />
                  </div>
                  </div>
                </Box>
                <Box
                  gridColumn="span 4"
                  gridRow="span 1"
                  backgroundColor={colors.primary[400]}
                  textAlign="center"
                >
                      <Typography
                        variant="h5"
                        fontWeight="600"
                        color={colors.grey[100]}
                      >
                        Key Assumptions
                      </Typography>
                      <div style={{ padding: '0 20px', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px' }}>
                  <div>
                  <Typography
                    variant="h6"
                    fontWeight="300"
                    color={colors.grey[100]}
                    align="left"
                  >
                    CO2 Tax
                  </Typography>
                  <Slider
                    aria-label="Temperature"
                    defaultValue={30}
                    onChangeCommitted={changeBarChartDataFun}
                    valueLabelDisplay="auto"
                    step={10}
                    marks
                    min={10}
                    max={110}
                    color="warning"
                  />
                  </div>
                  <div>
                  <Typography
                    variant="h6"
                    fontWeight="300"
                    color={colors.grey[100]}
                    align="left"
                  >
                    Damages
                  </Typography>
                  <Slider
                    aria-label="Temperature"
                    defaultValue={30}
                    onChangeCommitted={changeBarChartDataFun}
                    valueLabelDisplay="auto"
                    step={10}
                    marks
                    min={10}
                    max={110}
                    color="warning"
                  />
                  </div>
                  </div>

                </Box>
        {/*         <Box */}
        {/*           gridColumn="span 2" */}
        {/*           gridRow="span 2" */}
        {/*           backgroundColor={colors.primary[400]} */}
        {/*         > */}
        {/*  <Typography */}
        {/*                 variant="h5" */}
        {/*                 fontWeight="600" */}
        {/*                 color={colors.grey[100]} */}
        {/*               > */}
        {/*                 World Population over the Temperature */}
        {/*               </Typography> */}

        {/*           <Slider */}
        {/*             aria-label="Temperature" */}
        {/*             defaultValue={30} */}
        {/*             onChangeCommitted={changeGeographyChartDataFun} */}
        {/*             valueLabelDisplay="auto" */}
        {/*             step={10} */}
        {/*             marks */}
        {/*             min={10} */}
        {/*             max={110} */}
        {/*             color="warning" */}
        {/*           /> */}
        {/*         </Box> */}
                <Box
                  gridColumn="span 4"
                  gridRow="span 1"
                  backgroundColor={colors.primary[400]}
                >
                <Typography
                        variant="h5"
                        fontWeight="600"
                        color={colors.grey[100]}
                        textAlign="center"
                      >
                        Buildings and Industry
                      </Typography>
                    <div style={{ padding: '0 20px', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px' }}>
                  <div>
                  <Typography
                    variant="h6"
                    fontWeight="300"
                    color={colors.grey[100]}
                    align="left"
                  >
                    Energy Efficiency
                  </Typography>
                  <Slider
                    aria-label="Temperature"
                    defaultValue={30}
                    onChangeCommitted={changeBarChartDataFun}
                    valueLabelDisplay="auto"
                    step={10}
                    marks
                    min={10}
                    max={110}
                    color="warning"
                  />
                  </div>
                  <div>
                  <Typography
                    variant="h6"
                    fontWeight="300"
                    color={colors.grey[100]}
                    align="left"
                  >
                    Electrification
                  </Typography>
                  <Slider
                    aria-label="Temperature"
                    defaultValue={30}
                    onChangeCommitted={changeBarChartDataFun}
                    valueLabelDisplay="auto"
                    step={10}
                    marks
                    min={10}
                    max={110}
                    color="warning"
                  />
                  </div>
                  </div>

                </Box>
              </Box>
            {/* </div> */}


      {/* <h1>Progress Bar</h1>
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >

        <Box
          gridColumn="span 2"
          // gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Slider
            aria-label="Temperature"
            defaultValue={30}
            onChangeCommitted={changeLineChartDataFun}
            valueLabelDisplay="auto"
            step={10}
            marks
            min={10}
            max={110}
            color="warning"
          />
        </Box>
        <Box
          gridColumn="span 2"
          // gridRow="span 2"
          backgroundColor={colors.primary[400]}
        // overflow="auto"
        >
          <Slider
            aria-label="Temperature"
            defaultValue={30}
            onChangeCommitted={changeBarChartDataFun}
            valueLabelDisplay="auto"
            step={10}
            marks
            min={10}
            max={110}
            color="warning"
          />
        </Box>
        <Box
          gridColumn="span 2"
          // gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Slider
            aria-label="Temperature"
            defaultValue={30}
            onChangeCommitted={changeGeographyChartDataFun}
            valueLabelDisplay="auto"
            step={10}
            marks
            min={10}
            max={110}
            color="warning"
          />
        </Box>
        <Box
          gridColumn="span 2"
          // gridRow="span 2"
          backgroundColor={colors.primary[400]}
        // overflow="auto"
        >
          <Slider
            aria-label="Temperature"
            defaultValue={30}
            onChangeCommitted={changePieChartDataFun}
            valueLabelDisplay="auto"
            step={10}
            marks
            min={10}
            max={110}
            color="warning"
          />
        </Box> */}
      {/* </Box> */}
    </Box>
  );
}

export default Dashboard;
