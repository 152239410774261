import { createSlice } from "@reduxjs/toolkit";
import { tokens } from "../theme";

// let graphData = [
//     {
//         "Year": "2008",
//         "Temperature": 212
//     },
//     {
//         "Year": "2010",
//         "Temperature": 190
//     },
//     {
//         "Year": "2012",
//         "Temperature": 270
//     },
//     {
//         "Year": "2014",
//         "Temperature": 9
//     },
//     {
//         "Year": "2016",
//         "Temperature": 75
//     },
//     {
//         "Year": "2018",
//         "Temperature": 175
//     },
//     {
//         "Year": "2020",
//         "Temperature": 33
//     },
//     {
//         "Year": "2022",
//         "Temperature": 189
//     },
//     {
//         "Year": "2024",
//         "Temperature": 97
//     },
//     {
//         "Year": "2026",
//         "Temperature": 87
//     },
//     {
//         "Year": "2028",
//         "Temperature": 299
//     },
//     {
//         "Year": "2030",
//         "Temperature": 251
//     },
//     {
//     'Years':2016,
//     'Price_in_Dollar': 80
//     },
//     {
//     'Years':2019,
//     'Price_in_Dollar': 55
//     },
//     {
//     'Years':2018,
//     'Price_in_Dollar': 80
//     },
//     {
//     'Years':2023,
//     'Price_in_Dollar': 80
//     },
//     {
//     'Years':2024,
//     'Price_in_Dollar': 80
//     },
//     {
//     'Years':2020,
//     'Price_in_Dollar': 60
//     },
//     {
//     'Years':2022,
//     'Price_in_Dollar': 85
//     },
//     {
//     'Years':2023,
//     'Price_in_Dollar': 60
//     },
//     {
//     'Years':2025,
//     'Price_in_Dollar': 76
//     },
//     {
//     'Years':2028,
//     'Price_in_Dollar': 100
//     }
// ]
// console.log(process.env)

const lineChartUrl = process.env.REACT_APP_WITNESSEXPLORATION_BACKEND_URL + `/chart1/`;
const barChartUrl = process.env.REACT_APP_WITNESSEXPLORATION_BACKEND_URL + `/chart2/`;
const pieChartUrl = process.env.REACT_APP_WITNESSEXPLORATION_BACKEND_URL + `/chart3/`;
const DumbbellUrl = process.env.REACT_APP_WITNESSEXPLORATION_BACKEND_URL + `/chart4/`;
const CategoricalUrl = process.env.REACT_APP_WITNESSEXPLORATION_BACKEND_URL + `/chart5/`;
const BarChartUrl = process.env.REACT_APP_WITNESSEXPLORATION_BACKEND_URL + `/chart6/`;

//let lineChart;
const chartSlice = createSlice({
    name: "lineChart",
    initialState: {
        lineChart: (await (await fetch(lineChartUrl, {method: "GET"})).json())?.map((data, index) => {
        //   console.log("liCh", data);
            return {id: data.id, color: tokens("dark").blueAccent[((index+1) % 9) * 10 ], data: [{ x: data.Years, y: data.Price_in_Dollar }]
        }}) || [],
        barChart: (await (await fetch(barChartUrl)).json())?.map((data, index) => {

            return {id: data.id,year: data.Years, temperature: data.Temperature}
            }) || []
          ,
          pieChart: (await (await fetch(pieChartUrl)).json())?.map((data,index) =>
          {
            return {id:data.id,climate: data.Climate_Death,year:data.Year}
           
          }) || [],
        // pieChart: [
        //     {
        //         id: "CO2 Tax",
        //         label: "CO2 Tax",
        //         value: 239,
        //         color: "hsl(104, 70%, 50%)",
        //     },
        //     {
        //         id: "Damages",
        //         label: "Damages",
        //         value: 170,
        //         color: "hsl(162, 70%, 50%)",
        //     },
        //     {
        //         id: "CCUS",
        //         label: "France",
        //         value: 322,
        //         color: "hsl(291, 70%, 50%)",
        //     },
        //     {
        //         id: "Renewable",
        //         label: "Renewable",
        //         value: 503,
        //         color: "hsl(229, 70%, 50%)",
        //     },
        //     {
        //         id: "Fossil",
        //         label: "Fossil",
        //         value: 584,
        //         color: "hsl(344, 70%, 50%)",
        //     }
        // ],
        statChart: (await (await fetch(DumbbellUrl)).json())?.map((data,index) =>
          {
            console.log(data);
            return {value: data.Gross_In_Kcal,label:data.Year}
           
          }) || [],

    },
    reducers: {
        changeLineData(state, action) {
            if (action.payload) {
                state.lineChart = action.payload
            }
        },
        changeBarData(state, action) {
            if (action.payload) {
                state.barChart = action.payload
            }
        },
        changeGeographyData(state, action) {
            if (action.payload) {
                state.geographyChart = action.payload
            }
        },
        changePieData(state, action) {
            if (action.payload) {
                state.pieChart = action.payload
            }
        },
        changeStatData(state, action) {
            if (action.payload) {
                state.statChart = action.payload
            }
        }
    }
})

export const { changeLineData, changeBarData, changeGeographyData, changePieData, changeStatData } = chartSlice.actions;

export default chartSlice.reducer;
