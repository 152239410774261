import { Box, useTheme } from "@mui/material";
import Header from "../../components/Header";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tokens } from "../../theme";

const FAQ = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <div>
      <Box m="20px">
        <Header title="Witness Exploration"
        subtitle="About Witness Exploration" />

  {/*       <Accordion defaultExpanded> */}
  {/*         <AccordionSummary expandIcon={<ExpandMoreIcon />}> */}
  {/*           <Typography color={colors.greenAccent[500]} variant="h5"> */}
  {/*           The Witness Exploration App is a sophisticated tool designed for scenario modeling and data analysis. */}
  {/*             An Important Question */}
  {/*           </Typography> */}
  {/*         </AccordionSummary> */}
  {/*         <AccordionDetails> */}
  {/*           <Typography> */}

  {/*           </Typography> */}
  {/*         </AccordionDetails> */}
  {/*       </Accordion> */}
  {/*       <Accordion defaultExpanded> */}
  {/*         <AccordionSummary expandIcon={<ExpandMoreIcon />}> */}
  {/*           <Typography color={colors.greenAccent[500]} variant="h5"> */}
  {/*           It features an interactive dashboard with variable adjustment sliders, dynamically updating graphs, and is user-friendly. */}
  {/*             Another Important Question */}
  {/*           </Typography> */}
  {/*         </AccordionSummary> */}
  {/*         <AccordionDetails> */}
  {/*           <Typography> */}

  {/*           </Typography> */}
  {/*         </AccordionDetails> */}
  {/*       </Accordion> */}
  {/*       <Accordion defaultExpanded> */}
  {/*         <AccordionSummary expandIcon={<ExpandMoreIcon />}> */}
  {/*           <Typography color={colors.greenAccent[500]} variant="h5"> */}
  {/*           The app integrates DOE (Design of Experiments) allowing users to select and adjust key variables. */}
  {/*             Your Favorite Question */}
  {/*           </Typography> */}
  {/*         </AccordionSummary> */}
  {/*         <AccordionDetails> */}
  {/*           <Typography> */}

  {/*           </Typography> */}
  {/*         </AccordionDetails> */}
  {/*       </Accordion> */}
  {/*       <Accordion defaultExpanded> */}
  {/*         <AccordionSummary expandIcon={<ExpandMoreIcon />}> */}
  {/*           <Typography color={colors.greenAccent[500]} variant="h5"> */}
  {/*           It includes surrogate models to show impacts on various outcomes. */}
  {/*             Some Random Question */}
  {/*           </Typography> */}
  {/*         </AccordionSummary> */}
  {/*         <AccordionDetails> */}
  {/*           <Typography> */}

  {/*           </Typography> */}
  {/*         </AccordionDetails> */}
  {/*       </Accordion> */}
  {/*       <Accordion defaultExpanded> */}
  {/*         <AccordionSummary expandIcon={<ExpandMoreIcon />}> */}
  {/*           <Typography color={colors.greenAccent[500]} variant="h5"> */}
  {/*         It has efficient data storage and management systems for scalability and performance. */}
  {/*             The Final Question */}
  {/*           </Typography> */}
  {/*         </AccordionSummary> */}
  {/*         <AccordionDetails> */}
  {/*           <Typography> */}

  {/*           </Typography> */}
  {/*         </AccordionDetails> */}
  {/*       </Accordion> */}
      </Box>
      <Box m="20px">
      The Witness Exploration App is a sophisticated tool designed for scenario modeling and data analysis. It features an interactive dashboard with variable adjustment sliders, dynamically updating graphs, and is user-friendly. The app integrates DOE (Design of Experiments) allowing users to select and adjust key variables. It includes surrogate models to show impacts on various outcomes and has efficient data storage and management systems for scalability and performance.
      </Box>
    </div>
  );
};

export default FAQ;
